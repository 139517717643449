<template>
  <div class="roles_page">
    <div class="roles_page_box">
      <!-- 左侧信息 -->
      <LeftFilter
          ref="permissionRef"
          @search="handleSearch"
      />
      <!-- 右侧主内容 -->
      <FillPermission ref="fillPermissionRef"></FillPermission>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import FillPermission from "@/views/home/ConfigManagement/Permission/components/FillPermission";
import LeftFilter from "@/views/home/ConfigManagement/Permission/components/LeftFilter";
export default {
  name: "index",
  components: {
    LeftFilter,
    FillPermission,
  },
  setup() {
    const fillPermissionRef = ref()
    const permissionRef = ref()
    const handleSearch = (searchParams) => {
      fillPermissionRef.value.searchClick(searchParams);
    }

    return {
      fillPermissionRef,
      handleSearch,
      permissionRef
    }
  }
}
</script>

<style lang="less" scoped>
.roles_page{
  height: 100%;
  width: 100%;
  .no_margin{
    margin-left: 0 !important;
  }
  .roles_page_box{
    height: 100%;
    display: flex;
    >div{
      height: 100%;
      //background: #fff;
      border-radius: 4px;
    }
  }
}
</style>
