<template>
  <div class="roles_detail">
    <div class="roles_detail_title">
      <span style="margin-right: 10px;">权限列表</span>
    </div>
    <div class="info">
      <a-table
          ref="fillPermissionRef"
          :columns="columns"
          :data-source="fillPermissionList"
          rowKey="id"
          :scroll="{
        y: tableHeight,
        x: 700,
        scrollToFirstRowOnChange: true,
      }"
          :pagination="pagination"
      >
        <template #operation="{ record }">
          <a-space>
          <span :class="`mr16 ${record.type === '0' ? 'operationLink underline' : 'operationLink underline disabled'}`" @click="handleActive(record)" v-auth="['system:permission:enable']">启用</span>
            <span :class="`mr16 ${record.type === '1' ? 'redLink underline' : 'redLink underline disabled'}`" @click="handleDeactive(record)" v-auth="['system:permission:disable']">禁用</span>
          </a-space>
        </template>
      </a-table>
    </div>
  </div>

  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>

<script>
import { defineComponent, nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {formatTableCol, useTableHeight} from "@/utils/common";
import {message} from "ant-design-vue";
import {menuBtnStatus} from "@/utils/formatStatus";
import moment from "moment";
import ModalBox from '@/components/ModalBox'

export default defineComponent({
  name: "fillPermission",
  components: {ModalBox},
  props: {},
  emits: [],
  setup() {
    const { $api } = useGlobalPropertyHook();

    const fillPermissionRef = ref();
    const columns = [
      {
        title: "权限编码",
        dataIndex: "id",
        key: "id",
        ellipsis: true,
        width: 200,
      },
      {
        title: "权限名称",
        dataIndex: "menuName",
        key: "menuName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "创建时间",
        dataIndex: "createData",
        key: "createData",
        ellipsis: true,
        width: 150,
      },
      {
        title: "状态",
        dataIndex: "type",
        key: "type",
        customRender: formatTableCol(menuBtnStatus),
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "operation",
        slots: { customRender: "operation" },
        width: 100,
        fixed: "right",
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });

    const fillPermissionList = ref([]);
    let searchCondition = {};

    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
      };
      $api.queryMenuInfoAndBtnInfo(params).then((res) => {
        pagination.total = res.data.total;
        fillPermissionList.value = res.data.resource.map(item => {
          if(item !== null) {
            return {
              id: item.id,
              menuName: item.menuName,
              createData: moment(item.createData).format('YYYY-MM-DD HH:MM:SS'),
              type: item.type,
            }
          }
        }).filter(item => item !== undefined)
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const flag = ref()
    const handleOk = () => {
      const params = {
        id: modalData.value.id,
        menuName: modalData.value.menuName,
        type: modalData.value.type
      }
      switch (flag.value) {
        case 1:
          $api.queryStartUsingAndEndUsing(params).then(() => {
            message.success('操作成功')
            doSearch(searchCondition);
          });
          break
        case 2:
          $api.queryStartUsingAndEndUsing(params).then((res) => {
            if(res === 500){
              message.error('已有用户存在该权限，不可禁用')
            } else {
              message.success('操作成功')
              doSearch(searchCondition);
            }
          });
          break
        default:
          break
      }
    }
    const handleActive = (record) => {
      content.value = '确定启用这条数据吗?'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = record
      flag.value = 1
      modelValue.value = true
      /*Modal.confirm({
        title: '确定启用这条数据吗?',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '确定',
        onOk() {
          const params = {
            id: record.id,
            menuName: record.menuName,
            type: record.type
          }
          $api.queryStartUsingAndEndUsing(params).then(() => {
            message.success('操作成功')
            doSearch(searchCondition);
          });
        },
        cancelText: '取消',
      });*/
    }
    const handleDeactive = (record) => {
      content.value = '确定禁用这条数据吗?'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = record
      flag.value = 2
      modelValue.value = true
      /*Modal.confirm({
        title: '确定禁用这条数据吗?',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '确定',
        onOk() {
          const params = {
            id: record.id,
            menuName: record.menuName,
            type: record.type
          }
          $api.queryStartUsingAndEndUsing(params).then((res) => {
            if(res === 500){
              message.error('已有用户存在该权限，不可禁用')
            } else {
              message.success('操作成功')
              doSearch(searchCondition);
            }
          });
        },
        cancelText: '取消',
      });*/
    }

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      fillPermissionRef,
      columns,
      pagination,
      fillPermissionList,
      searchClick,
      tableHeight,
      doSearch,
      getSize,
      handleActive,
      handleDeactive,
      modelValue,
      content,
      showIconCom,
      handleOk,
    };
  }
});
</script>

<style lang="less" scoped>
.roles_detail{
  background: none;
  width: calc(100% - 251px);
  height: 100%;
  overflow: auto;
  //padding-left: 21px;
  margin-left: 21px;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  .roles_detail_title,
  .info_bottom_box_title {
    height: 85px;
    display: flex;
    align-items: center;
    padding: 0 21px;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;
    background: #fff;
    >span{
      font-size: 19px;
      font-weight: bold;
    }
    .operation-btns button{
      margin-left: 8px;
    }
  }
  .info{
    display: flex;
    width: 100%;
    max-height: calc(100% - 81px);
    background: #fff;
      .ant-table-wrapper {
        flex: 1;
        width: 0;
      }

      .org-info {
        width: 300px;
        //margin-left: 20px;
        background: #f4f4f4;
      }
    }


  :deep(.permission-list) {
    width: 100%;
    height: calc(100% - 100px);
    //position: absolute;
  }
}
</style>
