<template>
  <section
      :class="['side-nav-left', collapsed ? 'side-nav-left-collapsed' : '']"
  >
    <div :class="['header', collapsed ? 'header-collapsed' : '']">
      <p class="titleC">RMS菜单</p>
      <a-divider />
    </div>
    <div class="menu">
      <a-menu
          ref="permissionRef"
          class="menu-inline"
          mode="inline"
          :inline-collapsed="collapsed"
          v-model:openKeys="openKeys"
          v-model:selectedKeys="selectedKeys"
          @click="handleClick"
      >
        <template v-for="item in menusList" :key="item.child">
          <template v-if="!item.children">
            <a-menu-item :key="item.child">
              <span class="subMenuTitle">{{ item.title }}</span>
            </a-menu-item>
          </template>
          <template v-else>
            <a-sub-menu popupClassName="special-bg-font-color" :key="item.child" @titleClick="firstMenuClick">
              <template #title>
                <span class="subMenuTitle">{{ item.title }}</span>
              </template>
              <template v-for="item in item.children" :key="item.child">
                <a-menu-item>
                  <span class="menuTitle">{{ item.title }}</span>
                </a-menu-item>
              </template>
            </a-sub-menu>
          </template>
        </template>
      </a-menu>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  components: {},
  emits: ["search",],
  setup(props, ctx) {
    const permissionRef = ref();
    const { $api } = useGlobalPropertyHook();
    const state = reactive({
      collapsed: false,
      openKeys: [""],
      selectedKeys: [],
      preOpenKeys: [""],
    });
    const menusList = ref([])
    const getmenusList = () => {
      $api.allPermLevelsMenu().then((res) => {
        menusList.value = res.data
      });
    }
    const handleClick = ({key}) => {
      let currentState = {
        menuId: key
      }
      ctx.emit("search",currentState);
    };
    const firstMenuClick = (domEvent,key) => {
      console.log(key,domEvent)
      state.selectedKeys = []
      let currentState = {
        menuId: key
      }
      ctx.emit("search",currentState);
    }
    const doSearch = () => {
      ctx.emit("search");
    }

    watch(
        () => state.openKeys,
        (val, oldVal) => {
          state.preOpenKeys = oldVal;
        }
    );

    onMounted(() => {
      getmenusList();
    });

    return {
      permissionRef,
      handleClick,
      ...toRefs(state),
      menusList,
      getmenusList,
      firstMenuClick,
      doSearch,
    };
  },
});
</script>
<style lang="less" scoped>

.icon {
  color: #3182ce;
}
:deep(.ant-divider-horizontal) {
  margin: 0px;
}

.header {
  line-height: 38px;
  position: relative;
  padding: 0px 50px;

  &.header-collapsed {
    padding: 0px 10px;
  }

  .titleC {
    height: 38px;
    text-align: center;
    color: #3182ce;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
  }
}

.ant-menu {
  ::v-deep .ant-menu-item {
    color: #a5a5a5;
    height: 55px;
  }

  :deep(.ant-menu-item .ant-menu-item-only-child) {
    padding-left: 10px !important;
  }
}

.side-nav-left {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-width: 250px;

  &.side-nav-left-collapsed {
    min-width: 80px;
  }

  .subMenuTitle {
    font-size: 16px;
    color: #a5a5a5;
  }

  .menuTitle {
    font-size: 14px;
    //color: #A5A5A5;
  }

  .menu {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    margin-top: 5px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      width: 5px;
      height: 5px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  :deep(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
    width: auto;
    color: #2d3748 !important;
    padding-left: 10px !important;
  }

  :deep(.ant-menu-submenu.ant-menu-submenu-open) {
    .icon {
      background: #3182ce;
      color: #fff;
      border-radius: 8px;
      padding: 8px;
    }
  }

  :deep(.ant-menu-item-selected) {
    //选中状态
    background: #f7fafc !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: #2d3748;
    margin: 0px 16px;

    .subMenuTitle {
      transition: unset;
      color: #2d3748;
      font-weight: 500;
      font-size: 18px;
      //margin-left: 10px !important;
    }

    .ant-menu-title-content {
      // position: relative;
      //left: -20px;

      .icon {
        background: #3182ce;
        color: #fff;
        border-radius: 8px;
        padding: 8px;
      }
    }

    .ant-menu-item-icon {
      position: relative;
      left: -20px;
    }
  }

  :deep(.ant-menu-inline-collapsed) {
    // 折叠状态
    .ant-menu-submenu.ant-menu-submenu-open {
      .icon {
        color: #3182ce;
        background: #fff;
        padding: 0;
      }
    }

    .ant-menu-submenu {
      padding-left: 0 !important;
    }

    .ant-menu-item {
      width: calc(100% - 30px);
      padding: 0;
      margin: 5px 15px;
      position: relative;

      .anticon {
        position: absolute;
        left: 0px;
        top: 0;
        right: 0px;
        bottom: 0;
      }

      .ant-menu-item-icon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }

    .ant-menu-item-selected {
      margin: 10px;

      .ant-menu-title-content {
        margin-left: 0px;
      }

      .anticon {
        position: absolute;
        left: 14px;
        top: 8px;
        right: unset;
        bottom: unset;
      }

      .ant-menu-submenu-active {
        color: #fff;
      }
    }
  }

  :deep(.ant-menu-title-content) {
    display: flex;
    align-items: center;
  }

  :deep(.ant-menu-light .ant-menu-item) {
    padding-left: 34px !important;

    &:not(.ant-menu-item-selected) > .ant-menu-title-content {
      .subMenuTitle {
        //margin-left: 18px;
      }
    }

    &:hover {
      color: #2d3748;
    }
  }

  :deep(.ant-menu-sub .ant-menu-item) {
    padding-left: 26px !important;
  }

  :deep(.ant-menu-inline.ant-menu-root .ant-menu-item) {
    transition: unset;
  }

  :deep(.ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-title) {
    & > .ant-menu-title-content {
      .subMenuTitle {
       // margin-left: 18px;
      }
    }
  }

  :deep(.ant-menu-inline) {
    border-right: none;

    .ant-menu-item {
      margin-top: 0;
    }
  }

  :deep(.ant-menu-submenu) {
    .ant-menu-sub {
      margin-left: 40px;
      background: #ffffff;
      width: auto;
      border-left: 2px #ededed solid;
    }

    .ant-menu-submenu-title {
      height: 55px;
      transition: unset;
    }
  }

  :deep(.ant-menu-inline .ant-menu-item) {
    margin-bottom: 0px;

    &::after {
      border: 0px !important;
      background: #ffffff;
    }

    &:active {
      background-color: #ffffff;
    }
  }

  :deep(.ant-menu-item-selected, .ant-menu-item-selected
      a, .ant-menu-item-selected a:hover) {
    background: #2d3748;
  }

  :deep(.ant-menu):not(.ant-menu-inline-collapsed) {
    .ant-menu-submenu-title {
      padding-left: 24px !important;
    }
  }
}


:deep(.ant-menu-vertical) {
  border: 0px;
}

:deep(.ant-menu-submenu) {
  padding-left: 10px !important;

  &.ant-menu-submenu-open {
    padding-left: 2px !important;

    .subMenuTitle {
      transition: unset;
      color: #2d3748;
      font-weight: 500;
      font-size: 18px;
    }
  }
}

:deep(.ant-btn.ant-btn-link) {
  span {
    color: #12abdb;
  }
}
</style>
<style lang="less">
.special-bg-font-color.ant-menu {
  // 自定义独属全局样式
  .ant-menu {
    .ant-menu-item-selected {
      background-color: #3182ce;

      .menuTitle {
        color: #fff;
      }
    }
  }

  .menuTitle {
    color: #a5a5a5;
  }
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip {
  // ant组件未提供口子，只能写非独属全局
  .ant-tooltip-inner {
    background: #fff;
  }

  .ant-tooltip-arrow-content {
    background: #fff;
  }

  .subMenuTitle {
    color: #2d3748;
  }

  .anticon {
    display: none;
  }
}
</style>
